import { autoinject, bindable, containerless } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { UxBlade } from 'components/layout/ux-blade/ux-blade';
import notie from 'notie';
import { AuthenticationProvider } from 'providers/authentication-provider';
import {
    ActivityLogsApiClient,
    AlertXChangeLogCollection,
    BoxUserResponse,
    BusinessMap,
    BusinessMapsApiClient,
    CaseBook,
    CaseBookLightweightExtended,
    CaseBookReferencedEntity,
    CaseBookReferencedEntityTypes,
    CasesApiClient,
    LatestVesselRisk,
    LogsApiClient,
    Result,
    SicAlert,
    SicAlertOccurrence,
    SicApiClient,
    StatsApiClient,
    ThreatsApiClient,
    ThreatSummary,
    XchangeApiClient
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import Swal from 'sweetalert2';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { IThreatXChangeDetails, XChangeUtilities } from 'utilities/x-change-utilities';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { EventKeys } from '../../../enums/event-keys';
import { ThreatAction } from '../../../enums/threat-action';
import { environment } from '../../../utilities/environment';
import { SicUtilities } from '../../../utilities/sic-utilities';
import { ISicTraffic } from '../../../models/sic-traffic';
import { ThreatOccurrences } from './components/threat-occurrences/threat-occurrences';

@autoinject()
@containerless()
export class SicThreatInboxDetails {
    @bindable() private threatId: string;
    @bindable() private flagged: boolean = false;
    @bindable() private enableBusinessFeatures: boolean = false;
    @bindable() private fleetVessels: BusinessMap[];
    // Whether the details page should be displayed (shown) or not
    @bindable() private display: boolean = true;
    @bindable() private threatSummary: ThreatSummary;

    private activityLogs: Result[];
    private threat: SicAlert;
    private occurrences: SicAlertOccurrence[] = [];
    private businessMap: BusinessMap;
    private xchangeDetails: IThreatXChangeDetails;
    private xchangeBoxUsers: BoxUserResponse[];
    private tab: 'details' | 'resolve' | 'activity' | 'investigation' | 'analytics' = 'details';
    private loading: boolean = false;
    private company: string;
    private environment = environment;
    private kpis: any[];
    private sicTraffic: ISicTraffic | null;
    private threatStatusName: string;
    // TODO: Find a way to retrieve user agents of threat
    private userAgents: UAParser.IResult[];
    private shortIdCopyAnchor: HTMLAnchorElement;
    private vesselHasXchange: boolean;
    private caseBlade: UxBlade;
    private occurrencesComponent: ThreatOccurrences;
    private cases: CaseBook[];
    private macVendors: string[] = ['Unknown'];
    private vesselStats: LatestVesselRisk;
    private onThreatsActionSubscription: Subscription;

    constructor(
        private businessMapApi: BusinessMapsApiClient,
        private threatsApi: ThreatsApiClient,
        private sicApi: SicApiClient,
        private state: StateApi,
        private router: Router,
        private logsApi: LogsApiClient,
        private casesApi: CasesApiClient,
        private statsApi: StatsApiClient,
        private xchangeApi: XchangeApiClient,
        private authenticationProvider: AuthenticationProvider,
        private activityLogsApi: ActivityLogsApiClient,
        private eventAggregator: EventAggregator
    ) {
    }

    public async attached() {
        this.onThreatsActionSubscription = this.eventAggregator.subscribe(EventKeys.onThreatsAction, (data) => this.onExternalThreatsAction(data));
        await this.refresh();
    }

    public detached(): void {
        this.onThreatsActionSubscription.dispose();
    }

    public async refresh(): Promise<void> {
        this.loading = true;
        this.threat = null;
        this.businessMap = null;
        this.userAgents = null;
        this.xchangeDetails = null;
        this.activityLogs = undefined;
        this.cases = null;

        // Don't continue if there is no threatId, we won't be able to find information without it
        if (!this.threatId) {
            this.loading = false;
            return;
        }

        this.company = this.state.company();

        // Retrieve threat by id
        try {
            await Promise.all([
                this.threatsApi.getById(this.threatId, this.company)
                    .then((combinedThreat) => this.threat = combinedThreat.sicAlert),
            ]);
            if (!this.threat) throw new Error(`Threat response for id '${this.threatId}' is null`);
        } catch (error) {
            Toast.error('Threat could not be loaded');
            return;
        }

        this.sicApi.getOccurrences(this.threatId, this.company, 1000)
            .then((occurrences) => {
                this.occurrences = occurrences.items;
                this.sicTraffic = SicUtilities.extractTraffic(this.occurrences);
            });

        this.activityLogsApi.getForThreat(this.threatId, this.company).then((logs) => this.activityLogs = logs);

        // Look up any cases that are connected to this threat
        this.casesApi.lookup(this.threat.shortId, this.state.company())
            .then((cases) => this.cases = cases);

        try {
            // Lookup the vessel by siteId
            const ttSiteId = Utilities.parseSekoiaTtSiteId(this.threat.entity.name);
            // Retrieve BusinessMap by TT site id
            this.businessMap = this.fleetVessels.find((vessel) => vessel.ttSiteId === ttSiteId);

            this.statsApi.latestVesselRisk(this.businessMap.ttSiteId, this.state.company())
                .then((vesselStats) => this.vesselStats = vesselStats)
                .catch((error) => {
                    Toast.statsApiError();
                    throw error;
                });

            this.vesselHasXchange = this.businessMap.xChangeEnabled;
        } catch (error) {
            Toast.error('Vessel could not be loaded for threat');
        }

        if (this.vesselHasXchange) {
            let logs: AlertXChangeLogCollection;

            // Retrieve XChange Box users
            const boxUsersPromise = this.xchangeApi.boxUsers(this.businessMap.xchangeBoxSerialNo, this.company)
                .then((boxUsersResponse) => this.xchangeBoxUsers = boxUsersResponse)
                .catch(() => {
                    // Disabled toast on PROD/TEST
                    if (this.environment.debug) Toast.error('XChange Box users could not be loaded for threat');
                });

            try {
                // Retrieve additional data from XChange Logs
                logs = await this.logsApi.getByAlertId(this.threatId, this.company);
            } catch (error) {
                // Disabled toast on PROD/TEST as per request by PM
                if (this.environment.debug) Toast.error('XChange Logs could not be loaded for threat');
            }

            if (!logs) {
                this.loading = false;
                return;
            }

            try {
                // Only parse logs when there are some (we can get 204)
                this.xchangeDetails = XChangeUtilities.parseXChangeBoxLogs(logs);

                // Find user's user group after the box users have been retrieved
                boxUsersPromise.then(() => {
                    if (this.xchangeBoxUsers && this.xchangeBoxUsers.length > 0) {
                        this.xchangeDetails.users = this.xchangeDetails.users.map((user) => {
                            const xchangeBoxUser = this.xchangeBoxUsers.find((xbu) => xbu.username == user.username);
                            if (xchangeBoxUser) user.group = xchangeBoxUser.groupName;
                            return user;
                        });
                    }
                });

                // Find the vendor for each of the detected MAC addresses
                if (this.xchangeDetails?.machines?.length > 0) {
                    const macVendors = await Promise.all(
                        this.xchangeDetails.machines.map((machine) =>
                            this.threatsApi.vendorForMac(machine.macAddress, this.state.company())
                        )
                    );
                    // Replace any 'Not Found' responses with null values, these null values will be handled later when attempting to display
                    this.macVendors = macVendors.map((macVendor, index) =>
                        macVendor === 'Not Found'
                            ? null
                            : macVendor
                    );
                }
            } catch (error) {
                if (this.environment.debug) Toast.error('Error on fetching (mac) machine vendor information');
            }
        }

        // Finally, now that we're done loading, hide the page-covering loader
        this.loading = false;
    }

    /*
     * Threat Actions & Buttons
     */

    /**
     * Ignore the selected threat.
     */
    private async ignore(): Promise<void> {
        if (this.threat.status.name === 'Closed' || this.threat.status.name === 'Rejected') {
            Toast.warning(`Threat cannot be ignored as it's already closed or ignored`);
            return;
        }
        const domain = this.sicTraffic?.domain ?? null;
        const ip = this.sicTraffic?.destinationIp ?? null;
        const dialog = await Swal.fire({
            title: 'Ignore this threat?',
            html: `Ignoring this threat means you consider this threat to be a "false positive". No further actions can be made on ignored threats.<br />` +
                `<textarea rows="4" cols="40" id="comment" placeholder="A comment is optional but could be very useful for backtracking" class="swal2-textarea mx-0"></textarea> <br />` +
                `<div class="w-100 text-left ${domain === null ? 'd-none' : ''}">
                    <input type="checkbox" id="ignore-domain" class="swal2-checkbox m-0"><label for="ignore-domain" class="form-check-label ml-2"><small>Do not raise threats about domain ‘<strong>${domain}</strong>‘ in future</small></label>
                </div>
                <div class="w-100 text-left ${ip === null ? 'd-none' : ''}">
                    <input type="checkbox" id="ignore-ip" class="swal2-checkbox m-0"><label for="ignore-ip" class="form-check-label ml-2"><small>Do not raise threats about IP ‘<strong>${ip}</strong>‘ in future</small></label>
                </div>`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, ignore it',
            preConfirm: () => {
                return {
                    comment: document.getElementById('comment') as HTMLInputElement,
                    ignoreDomain: document.getElementById('ignore-domain') as HTMLInputElement,
                    ignoreIp: document.getElementById('ignore-ip') as HTMLInputElement
                };
            }
        });

        // Short-circuit when the dialog was dismissed
        if (dialog.dismiss)
            return;

        this.eventAggregator.publish(EventKeys.beforeThreatAction, {
            action: ThreatAction.Ignore,
            threat: this.threat,
            comment: dialog.value.comment.value
        });

        this.loading = true;

        try {
            await this.threatsApi.ignore(this.threatId, this.company, dialog.value.comment.value, dialog.value.ignoreDomain.checked ? this.sicTraffic?.domain : undefined, dialog.value.ignoreIp.checked ? this.sicTraffic?.destinationIp : undefined);

            // For optimistic UI:
            // 1. Update the threat's status so the action's result is immediately visible
            this.threat.status.name = 'Rejected';
            this.threatSummary.status = 'Rejected';
            // 2. Add an activity log so the UI will immediately display the comment
            this.addActivityLog(ThreatAction.Ignore, dialog.value.comment.value);

            notie.alert({ position: 'bottom', text: `OK, threat is ignored`, type: 'success' });

            this.eventAggregator.publish(EventKeys.onThreatAction, {
                action: ThreatAction.Ignore,
                threat: this.threat,
                comment: dialog.value.comment.value
            });
        } catch (error) {
            notie.alert({ position: 'bottom', text: `Oops, ${error}`, type: 'error' });
        } finally {
            this.loading = false;
        }
    }

    /**
     * Resolve, or close, the selected threat.
     */
    private async close(): Promise<void> {
        if (this.threat.status.name === 'Closed' || this.threat.status.name === 'Rejected') {
            Toast.warning(`Threat cannot be closed as it's already closed or ignored`);
            return;
        }

        const dialog = await Swal.fire({
            title: 'Mark as closed?',
            html: `Closing a threat means you consider this threat to be "handled" and no further actions can be applied. If the threat is still detected afterwards, it will pop back up in the future as a new threat.`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, close',
            input: 'textarea',
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        });

        // Short-circuit when the dialog was dismissed
        if (dialog.dismiss)
            return;

        this.eventAggregator.publish(EventKeys.beforeThreatAction, {
            action: ThreatAction.Close,
            threat: this.threat,
            comment: dialog.value
        });

        this.loading = true;

        try {
            await this.threatsApi.close(this.threatId, this.company, dialog.value);

            // For optimistic UI:
            // 1. Update the threat's status so the action's result is immediately visible
            this.threat.status.name = 'Closed';
            this.threatSummary.status = 'Closed';
            // 2. Add an activity log so the UI will immediately display the comment
            this.addActivityLog(ThreatAction.Close, dialog.value);

            notie.alert({ position: 'bottom', text: `OK, threat is closed`, type: 'success' });

            this.eventAggregator.publish(EventKeys.onThreatAction, {
                action: ThreatAction.Close,
                threat: this.threat,
                comment: dialog.value
            });
        } catch (error) {
            notie.alert({ position: 'bottom', text: `Oops, ${error}`, type: 'error' });
        } finally {
            this.loading = false;
        }
    }

    private async flag(): Promise<void> {
        this.flagged = true;

        try {
            await this.threatsApi.flag(this.threatId, this.state.company());
            this.addActivityLog(ThreatAction.Flag);
        } catch (error) {
            this.flagged = false;
        }
    }

    private async unflag(): Promise<void> {
        this.flagged = false;

        try {
            await this.threatsApi.unflag(this.threatId, this.state.company());
            this.addActivityLog(ThreatAction.Unflag);
        } catch (error) {
            this.flagged = true;
        }
    }

    private onExternalThreatsAction(data: { threats: ThreatSummary[], action: ThreatAction, comment?: string }): void {
        const affectsThisThreat = data.threats.some(t => t.id === this.threat.uuid);

        // When the action doesn't affect this threat, no changes need to be made to the UI, so short-circuit
        if (!affectsThisThreat)
            return;

        const actionedThreat = data.threats.find(t => t.id === this.threat.uuid);

        // Update the threat's status so the action's result is immediately visible
        this.threat.status.name = actionedThreat.status;
        this.threatSummary.status = actionedThreat.status;

        // Add an activity log so the UI will immediately display the action + comment
        this.addActivityLog(data.action, data.comment);
    }

    private addActivityLog(action: ThreatAction, comment?: string): void {
        this.activityLogs.unshift(
            new Result({
                action,
                activityType: 'Threat',
                companyId: this.company,
                content: comment ?? null,
                date: new Date(),
                success: true,
                userName: 'you'
            })
        );
    }

    /**
     * Generates a link to this specific threat and copies it to the clipboard
     */
    private copyLink(): void {
        try {
            // Generate a direct link to this threat based on its short id
            const directLink = this.router.generate('threats', {
                id: this.threat.shortId
            }, { absolute: true });
            // Copy link to clipboard
            Utilities.copyToClipboard(directLink);
        } catch (error) {
            Toast.warning('Unable to copy link to Threat to clipboard');
            return;
        }

        Toast.info('Link to Threat copied to clipboard');
    }

    /**
     * Copies the threat's shortId to the clipboard
     */
    private copyShortcode(): void {
        try {
            Utilities.copyToClipboard(this.threat.shortId);
        } catch (error) {
            Toast.warning('Unable to copy Threat Id to clipboard');
            return;
        }

        Toast.info('Threat Id copied to clipboard');
    }

    private navigateToVessel(): void {
        // As routing to a child route of a different parent route is not possible, navigate to the URL fragment instead
        // See https://github.com/aurelia/router/issues/89
        // where https://github.com/aurelia/router/issues/89#issuecomment-282079392 could be a solution.
        this.router.navigate(`/vessels/${this.businessMap.ttSiteId}`);
    }

    private async addToCase(caseBook: CaseBookLightweightExtended): Promise<void> {
        const response = await Swal.fire({
            title: 'Add threat to Case',
            html: `Are you sure you want to add this threat to ${caseBook.title}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, add',
        });

        if (response.value) {
            await this.attachThreatToCase(caseBook);

            this.eventAggregator.publish(EventKeys.onThreatAddedToCase, { caseBook, threat: this.threat });
        }
    };

    private createCase(): void {
        this.caseBlade.show();
    }

    private onCaseBladeHide = async (caseBook: CaseBookLightweightExtended): Promise<void> => {
        this.caseBlade.hide();

        // If a CaseBook is present, a CaseBook was created in the CaseBlade. We now have to attach the threat to the
        // newly created CaseBook.
        if (caseBook) {
            await this.attachThreatToCase(caseBook);

            this.eventAggregator.publish(EventKeys.onThreatAddedToCase, { caseBook, threat: this.threat });
        }
    };

    private async attachThreatToCase(caseBook: CaseBookLightweightExtended): Promise<void> {
        try {
            this.loading = true;

            const referencedEntity = new CaseBookReferencedEntity({
                createdAt: new Date(),
                createdBy: this.authenticationProvider.profile.sub,
                entityId: this.threat.shortId,
                entityType: CaseBookReferencedEntityTypes.Threat,
                label: this.threat.shortId
            });

            await this.casesApi.attachEntity(caseBook.id, this.state.company(), referencedEntity);
            await this.refresh();

            Toast.success(`Successfully added threat to Case`);

            this.loading = false;
        } catch (error) {
            Toast.error(`Could not add threat to Case`);
            this.loading = false;
        }
    }

    private showOccurrences(): void {
        if (this.occurrencesComponent)
            this.occurrencesComponent.openBlade();
    }

    private navigateToCase(caseBook: CaseBook): void {
        // As routing to a child route of a different parent route is not possible, navigate to the URL fragment instead
        // See https://github.com/aurelia/router/issues/89
        // where https://github.com/aurelia/router/issues/89#issuecomment-282079392 could be a solution.
        this.router.navigate(`cases/${caseBook.id}`);
    }
}
